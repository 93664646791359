import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import { styled } from "@mui/material/styles";
import { Box, Typography, Container } from "@mui/material";

import GuideNav, {
  menuItems,
} from "components/viewContents/guideView/GuideNav";

import ReactPlayer from "react-player";

const Guide = () => {
  const { guide_id } = useParams();
  const [isClosing, setIsClosing] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0]);

  const url = `https://www.u-stc.net/guide/${guide_id}.mp4`;
  const title = selectedMenuItem.text;

  const listItemStyle = (itemText) => ({
    backgroundColor: selectedMenuItem === itemText ? "#0000000a" : "inherit", // 활성화 시 배경색 변경
    // 추가 스타일은 필요에 따라 여기에 작성
    "&:hover": {
      backgroundColor: "#0000000a", // 호버 시 배경색 변경
    },
  });

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  return (
    <Container
      sx={{
        pt: 8,
        pb: 6,
      }}
      maxWidth="lg"
    >
      <Typography component="h2" variant="h3" sx={{ marginBottom: "32px" }}>
        영상가이드
      </Typography>
      <Box display={"flex"} sx={{ justifyContent: "space-between" }}>
        <Box>
          <Typography
            component="h3"
            variant="h5"
            sx={{ marginBottom: "32px", lineHeight: 2 }}
          >
            영상
          </Typography>
          <GuideNav
            mobileOpen={mobileOpen}
            handleDrawerClose={handleDrawerClose}
            setSelectedMenuItem={setSelectedMenuItem}
            menuItems={menuItems}
            listItemStyle={listItemStyle}
          />
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{ marginBottom: "16px", lineHeight: 2 }}
          >
            {title}
          </Typography>
          <ReactPlayer url={url} playing={true} controls={true} />
        </Box>
      </Box>
    </Container>
  );
};
export default Guide;
