// import * as React from "react";
// import React, { useEffect } from "react";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Link from "pages/Link/Link";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  // Link,
  GlobalStyles,
  IconButton,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  Box,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import logo from "../assets/images/logo.svg";
import Login from "views/Login";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import TopMenuSearch from "./TopMenuSearch";
import TestSignUpVerificationQR from "views/TestSignUpVerificationQR";

// import { ReactComponent as logo
// import ModalContinue from "./modal/ModalContinue";

// 컬러 테마 저장해서 순서대로 주는것
// const defaultTheme = createTheme({
//   palette: {
//     primary: "#29314A",
//     // secondary: purple,
//   },
// });
const defaultTheme = createTheme();

// const theme = createTheme({
//   palette: {
//     primary: lime,
//     secondary: purple,
//   },
// });

export default function TopMenu({ value, onClick, onRefreshClick }) {
  // 로그인, 로그아웃 버튼 S
  // 토큰
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // console.log("####위치확인pathname", pathname);
  const access = sessionStorage.getItem("access");
  const refresh = sessionStorage.getItem("refresh");

  // 로그인 유저 정보
  //  const company = sessionStorage.getItem("company");
  //  const userDID = sessionStorage.getItem("did");
  const userName = sessionStorage.getItem("name");
  // 로그인 유저 정보

  console.log("## access", access);
  console.log("## refresh", refresh);
  console.log("## refresh", userName);

  useEffect(() => {
    console.log("####11위치확인pathname", pathname);
  }, []);

  // 로그인, 로그아웃 버튼 E

  // const navigate = useNavigate();

  // const loginMove = () => {
  //   navigate("/login");
  //   // console.log("loader", loader);
  // };

  const loader = () => {
    navigate("/login");
    console.log("loader", loader);
  };

  // useState
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchBtn, setSearchBtn] = useState(); // 검색버튼
  const [isOpenTopMenuSearch, setIsOpenTopMenuSearch] = useState(false); // 검색폼(탑메뉴검색창)

  const handleClickSearchOpen = (params) => {
    // setIsOpenModalHandlerSelect(true);
    setSearchBtn(params.target.value);
    setIsOpenTopMenuSearch(true);
  };

  // QR 코드 생성을 위한 상태 추가
  const [showQR, setShowQR] = useState(false);
  // const [qrValue, setQrValue] = useState("");

  const handleQRTestClick = () => {
    setShowQR(!showQR);
    // setShowQR(true); // QR 코드를 보여줄 것인지 결정하는 상태를 true로 설정
    // setQrValue("https://example.com"); // QR 코드에 사용될 값 설정, 실제 사용 시 동적인 값으로 설정 가능
  };

  const handleChange = (event) => {
    console.log("handleChange", handleChange);
    console.log("event.target.checked", event.target.checked);
    if (event.target.checked) {
      loader();
    }
    setAuth(event.target.checked);
    // setAuth(loader);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowQR(false);
    setIsOpenTopMenuSearch(false);
  };

  const logoSx = {
    // zIndex: "1",
    "& .MuiToolbar-root": {
      // flexGrow: 1,
    },
    "& .MuiTypography-root": {
      // display: "flex",
      // flex: "4",
      // height: "90px",
      // flexGrow: 1,
    },
    // "& .MuiToolbar-root": {
    //   "& h1": {
    //     display: "flex",
    //     flex: "4",
    //     height: "90px",
    //     flexGrow: 1,
    //     color: "red",
    //   },
    // },
    "& .MuiTypography-root": {
      // display: "flex",
      // flex: "4",
      // height: "90px",
      // flexGrow: 1,
      // 여기서부터 적용되서 햇는데, 일단 svg가 제대로 안먹어서 일단 Img 로고로 해두장 ㅜㅜ
      // color: "red",
      // display: "block",
      // textIndent: "-9999px",
      // width: "196px",
      // height: "42px",
      // background: "url(../assets/images/logo.svg) left center no-repeat",
      // webkitBackgroundSize: "100%",
      // // backgroundSize: '100%',
      // transition: ".3s all",
      // backgroundSize: "contain",
      // 여기까지 ...로고
      // 밑에거는 적용 중
      // "& .MuiLink-root": {
      //   display: "block",
      //   textIndent: "-9999px",
      //   width: "196px",
      //   height: "42px",
      //   background: "url(../assets/images/logo.svg) left center no-repeat",
      //   webkitBackgroundSize: "100%",
      //   // backgroundSize: '100%',
      //   transition: ".3s all",
      //   backgroundSize: "contain",
      // },
    },
  };

  // // 준비중 S
  // const [continueBtn, setContinueBtn] = useState();
  // const [isOpenContinueModal, setIsOpenContinueModal] = useState(true);
  // // const [selectContractNumber, setSelectContractNumber] = useState();
  // const handleClickContinueOpen = (e) => {
  //   // console.log("@@@params", params);
  //   // console.log("handleClickQrOpen", handleClickQrOpen);
  //   // alert("handleClickQrOpen");
  //   setIsOpenContinueModal(true);
  //   setContinueBtn(e.target.value);
  //   // setSelectContractNumber(params.data.contract_number);
  // };
  // const handleContinueClose = () => {
  //   setIsOpenContinueModal(false);
  // };
  // // 준비중 E

  // useEffect (() => {

  // });

  const LogOutBtn = () => {
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
    sessionStorage.removeItem("user_type");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("company");
    sessionStorage.removeItem("user_id");
    // window.location.reload();
    // navigate("/home");
    navigate("/login", { replace: true });
  };

  const LogInBtn = () => {
    navigate("/login");
    // if (onClick) {
    //   return <Login />;
    // }
    // navigate("/login");
    // pathname
    // window.location.reload();
  };
  const LogInBtns = () => {
    if (access && refresh) {
      return (
        // <Link
        //   href="/login"
        //   variant="button"
        //   color="text.primary"
        //   sx={{ my: 1, mx: 1.5, color: "#29314A" }}
        //   underline="none"
        // >
        //   Logout
        // 13px
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: 2.75,
              mr: 1.625,
            }}
          >
            <AccountCircleIcon
              fontSize="small"
              sx={{
                color: "#29314A",
                fontWeight: "400",
                margin: "0 auto",
              }}
            />
            <Typography
              component="span"
              variant="body1"
              sx={{ fontSize: "0.5rem" }}
            >
              {userName}님
            </Typography>
          </Box>
          <Button
            onClick={LogOutBtn}
            sx={{ position: "relative" }}
            // variant="outlined"

            // sx={{
            //   my: 1,
            //   mx: 1.5,
            // }}
          >
            <Typography
              component="span"
              variant="body1"
              sx={{
                // fontSize: "0.625rem",
                fontSize: "0.8125rem",
                position: "absolute",
                top: "-9px",
                left: "17px",
                color: "#29314A",
                textTransform: "unset",
                // display: "flex",
                // textAlign: "center",
                // width: "100%",
                // justifyContent: "center",
                // alignItems: "center",
                // fontWeight: "600",
              }}
            >
              로그아웃
            </Typography>
            <LogoutIcon
              fontSize="large"
              sx={{
                color: "#29314A",
                fontWeight: "400",
              }}
            />
            {/* Logout */}
          </Button>
        </>
        // </Link>
      );
    } else {
      return (
        // <Link
        //   href="/login"
        //   variant="button"
        //   color="text.primary"
        //   sx={{ my: 1, mx: 1.5, color: "#29314A" }}
        //   underline="none"
        // >
        //   Login
        <Button
          onClick={LogInBtn}
          // variant="outlined"

          // sx={{ my: 1, mx: 1.5 }}
        >
          <Typography
            component="span"
            variant="body1"
            sx={{
              // fontSize: "0.625rem",
              fontSize: "0.8125rem",
              position: "absolute",
              top: "-9px",
              left: "17px",
              color: "#29314A",
              textTransform: "unset",
              // display: "flex",
              // textAlign: "center",
              // width: "100%",
              // justifyContent: "center",
              // alignItems: "center",
              // fontWeight: "600",
            }}
          >
            로그인
          </Typography>
          <LoginIcon
            fontSize="large"
            sx={{
              color: "#29314A",
              fontWeight: "400",
            }}
          />
          {/* Login */}
        </Button>
        // </Link>
      );
    }
  };

  // 페이지 조회
  const [isSearchActive, setIsSearchActive] = useState(true);

  const SearchActiveBtns = (e) => {
    if (onClick) {
      isSearchActive(e);
      onClick(e);
      return;
    }
    console.log("###구매관리 검색버튼", SearchActiveBtns);
    e.preventDefault();
    setIsSearchActive(e.target.value);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar
          sx={{
            height: "90px",
            flexWrap: "wrap",
            boxShadow: 3,
            // width: '8rem',
            // height: '5rem',
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#101010" : "#fff",
            // color: (theme) =>
            //   theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
            // p: 1,
            // m: 1,
            // borderRadius: 2,
            // textAlign: "center",
            // fontSize: "0.875rem",
            // fontWeight: "700",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            color="inherit"
            noWrap
            flexGrow="1"
            lineHeight={0}
            // sx={{ flexGrow: 1 }}
            sx={logoSx}
          >
            <Link href="/" underline="none">
              {/* USTC */}
              <img
                src={logo}
                alt="logo"
                sx={{ width: "150px", height: "auto" }}
                size="sm"
              />
            </Link>
          </Typography>
          <nav>
            <Link
              href="/guide/signup"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
              영상가이드
            </Link>
            <Link
              href="/intro"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
              플랫폼소개
            </Link>
            <Link
              href="/statistics"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
              플랫폼통계
            </Link>
            {/* <Link
              href="/filelist"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
              파일관리
            </Link> */}
            <Link
              href="/category"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
              카테고리
            </Link>
            <Link
              href="/mypage"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
              마이페이지
            </Link>
            {/* <Link
              href="/contractlist"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
              계약관리
            </Link> */}
            {/* {isSearchActive && ( */}
            {/* <Link
              href="/buylist"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
            
              <span onClick={onRefreshClick}>구매관리</span>
            </Link> */}
            {/* <span onClick={SearchActiveBtns} /> */}
            {/* )} */}

            {/* <Link
              href="/did"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
            >
              사용자관리
            </Link> */}
            {/* DID관리 */}
            {/* <Link
              href="/tf"
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, color: "#29314A" }}
              underline="none"
              // value={continueBtn}
              // onClick={handleClickContinueOpen}
              // value={value}
              // onClick={onClick}
            >
              TF관리
            </Link> */}
          </nav>
          {/* 계정 아이콘 S */}
          {/* {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )} */}
          {/* 계정 아이콘 E */}
          {/* 로긴 토글 S */}
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={auth}
                  onChange={handleChange}
                  aria-label="login switch"
                />
              }
              label={auth ? "Logout" : "Login"}
            />
          </FormGroup> */}
          {/* 로긴 토글 E */}
          {/* <Button onClick={handleQRTestClick}>큐알테스트 버튼</Button> */}
          <IconButton
            value={searchBtn}
            onClick={handleClickSearchOpen}
            color="secondary"
            aria-label="Search"
            // sx={{ mr: 2.75 }} // 25px 3.125 22px
          >
            <SearchIcon
              fontSize="large"
              sx={{
                color: "#29314A",
                fontWeight: "400",
              }}
            />
          </IconButton>
          <LogInBtns />
          {/* {access && refresh ? (
            <Button
              onClick={loginMove}
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
            >
              Logout
            </Button>
          ) : (
            <Button
              onClick={loginMove}
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
            >
              Login
            </Button>
          )} */}
        </Toolbar>
      </AppBar>
      {/* {isOpenContinueModal ? (
        <ModalContinue
          handleClose={handleContinueClose}
          open={isOpenContinueModal}
          // onClick={}
          onClose={handleContinueClose}
          isOnClick={handleContinueClose}
        />
      ) : null} */}
      {/* 로그인 화면 S */}
      {/* {auth && (
        <div>
          <Login />
        </div>
      )} */}
      {/* 로그인 화면 E */}
      {isOpenTopMenuSearch && (
        <TopMenuSearch
          handleClose={handleClose}
          // isOpen={isOpenTopMenuSearch}
          // handleClose={handleClose}
          // onFinalClickInfoChange={onSelectedCoNMgrChange}
          // onClose={handleClose}
        />
      )}

      {showQR && (
        <TestSignUpVerificationQR
          handleCloseQR={handleClose}
          // isOpen={isOpenTopMenuSearch}
          // handleClose={handleClose}
          // onFinalClickInfoChange={onSelectedCoNMgrChange}
          // onClose={handleClose}
        />
      )}
      {/* {showQR && (
        <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
          <QRCode value={qrValue} size={256} />
        </Box>
      )} */}
    </ThemeProvider>
  );
}
