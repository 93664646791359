import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import client from "assets/js/commonApi";
import CryptoJS, { AES } from "crypto-js";
import OneSignal from "react-onesignal";
import { v4 } from "uuid";
import dayjs from "dayjs";

const initDialog = {
  open: false,
  context: "",
  value: "",
  onChange: () => {},
  onClick: () => {},
  onClose: () => {},
};
const initGroup = {
  group_id: "",
  group_name: "",
  storage_sub_id: "",
  group_user_type: [],
};
const initFile = {
  upload_main_id: "",
  upload_file_id: "",
  data_title: "",
  data_main_cat: "",
  data_sub_cat: "",
  data_price: 0,
  data_desc: "",
  data_ext: "",
  data_size: 0,
  collect_from_dtm: dayjs(),
  collect_to_dtm: dayjs(),
  collect_process: "",
  collect_machine: "",
  colect_product: "",
  img_url: "",
  column_info: "",
};
const initUploadFile = {
  upload_main: {
    folder_id: "", // 폴더 ID
    data_title: "파일 업로드 테스트 - 타이틀", //
    data_main_cat: "C34", // 메인 카테고리
    data_sub_cat: "테스트", // 서브 카테고리
    data_price: 1000, // 가격
    data_desc: "파일 업로드 테스트 - 설명", // 설명
    data_ext: "", // 확장자
    data_size: "", // 데이터 크기
    collect_to_dtm: "2024-05-01", // 수집기간
    collect_from_dtm: "2024-05-31", // 수집기간
    collect_machine: "수집장비 테스트", // 수집장비
    collect_product: "수집공정 테스트", // 수집공정
    collect_process: "수집제품 테스트", // 수집제품
    upload_subs: [], // 컬럼 정보
  },
};
const initContract = {
  contract_sub_id: "",
  seller_name: "",
  seller_company_name: "",
  seller_group_name: "",
  buyer_name: "",
  buyer_company_name: "",
  code_name: "",
  contract_title: "",
  to_dtm: "",
  from_dtm: "",
  ins_dtm: "",
  upd_dtm: "",
};
// folderInfo type
// { folder_id, folder_name }
// fileInfo type
// { upload_file_id, file_name, file_ext }
const initQRcode = {
  type: "storage/key",
  data: {
    pulling_key: "",
    encrypted_key: "",
    storage_sub_id: "",
  },
};

export default function useStorage() {
  const subscriptionId = OneSignal.User.PushSubscription.id ?? v4().toString();
  const [dialog, setDialog] = useState(initDialog);
  const [explorer, setExplorer] = useState({
    open: false,
    handleMove: () => {},
    handleClose: () => setExplorer((pre) => ({ ...pre, open: false })),
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 10000,
    handleClose: () => setSnackBar((pre) => ({ ...pre, open: false })),
  });
  const [fromDtm, setFromDtm] = useState(dayjs());
  const [toDtm, setToDtm] = useState(dayjs());
  const [group, setGroup] = useState(initGroup);
  const [groupList, setGroupList] = useState([]);

  const [storageDirectory, setStorageDirectory] = useState();

  const [sellContractList, setSellContractList] = useState([]);
  const [sellContract, setSellContract] = useState(initContract);
  const [path, setPath] = useState("/root");
  const [folder, setFolder] = useState();
  const [file, setFile] = useState(initFile);
  const [qrcode, setQRcode] = useState(initQRcode);
  const [uploadFile, setUploadFile] = useState({
    file: undefined,
    fileInfos: initUploadFile,
  });
  const [downloadFile, setDownloadFile] = useState({
    fileInfos: initUploadFile,
  });

  const [isShowFilePopup, setIsShowPopup] = useState(false);
  const [isShowQRUploadPopup, setIsShowQRUploadPopup] = useState(false);
  const [isShowQRDownloadPopup, setIsShowQRDownloadPopup] = useState(false);

  const navigate = useNavigate();

  const onFromChange = (newValue) => {
    if (dayjs(newValue).isValid() === false) {
      setFromDtm(dayjs());
      return;
    }

    const dateInterval = toDtm.diff(newValue, "days");

    // 시작일시를 한달 이상 간격으로 선택
    if (dateInterval > 30) {
      setToDtm(dayjs(newValue).add(30, "days"));
    }

    // 종료일시보다 시작일시를 느리게 지정할 경우
    if (dateInterval < 0) {
      setToDtm(dayjs(newValue).add(30, "days"));
    }

    setFromDtm(newValue);
  };

  const onToChange = (newValue) => {
    if (dayjs(newValue).isValid() === false) {
      setToDtm(dayjs());
      return;
    }

    const dateInterval = toDtm.diff(newValue, "days");

    // 시작일시를 한달 이상 간격으로 선택
    if (dateInterval > 30) {
      setFromDtm(dayjs(newValue).subtract(30, "days"));
    }

    // 종료일시보다 시작일시를 느리게 지정할 경우
    if (dateInterval < 0) {
      setFromDtm(dayjs(newValue).subtract(30, "days"));
    }

    setToDtm(newValue);
  };

  const showSnackBar = (message) =>
    setSnackBar((pre) => ({
      ...pre,
      open: true,
      message: message,
    }));

  // internal function
  const getDirectory = (_folder) => {
    if (!group?.storage_sub_id) {
      return;
    }

    if (_folder) {
      if (_folder.folder_id === "root") {
        // root인 경우
        client
          .post("/storage/root", { storage_sub_id: group.storage_sub_id })
          .then((res) => {
            const data = res?.data.data;
            const storage_sub_id = group.storage_sub_id;
            const folder_info = data.folder_info ?? [];
            const file_info = data.file_info ?? [];

            setStorageDirectory({ storage_sub_id, folder_info, file_info });
          });
      } else {
        client
          .post("/folder/detail", {
            folder_id: _folder?.folder_id,
          })
          .then((res) => {
            const data = res?.data.data;
            const storage_sub_id = group.storage_sub_id;
            const folder_info = data.folder_info ?? [];
            const file_info = data.file_info ?? [];

            setStorageDirectory({ storage_sub_id, folder_info, file_info });
          });
      }

      return;
    }

    // 해당 folder에서 디렉토리 조회하기
    if (!folder || folder.folder_id === "root") {
      // root인 경우
      client
        .post("/storage/root", { storage_sub_id: group.storage_sub_id })
        .then((res) => {
          const data = res?.data.data;
          const storage_sub_id = group.storage_sub_id;
          const folder_info = data.folder_info ?? [];
          const file_info = data.file_info ?? [];

          setStorageDirectory({ storage_sub_id, folder_info, file_info });
        });
    } else {
      client
        .post("/folder/detail", {
          folder_id: folder.folder_id,
        })
        .then((res) => {
          const data = res?.data.data;
          const storage_sub_id = group.storage_sub_id;
          const folder_info = data.folder_info ?? [];
          const file_info = data.file_info ?? [];

          setStorageDirectory({ storage_sub_id, folder_info, file_info });
        });
    }
    // 해당 folder에서 디렉토리 조회하기
  };
  // internal function

  const onLoadGroup = () => {
    client.post("/group/list", group).then((res) => {
      const _ = res?.data?.data;
      const data = _.filter((group) => group.group_name !== "결제그룹");

      setGroupList(data);
    });
  };

  const onUnbindClick = () => {
    setFile(initFile);
  };

  const onGroupClick =
    (group = initGroup) =>
    () => {
      setPath("/root");
      setFolder(undefined);
      setGroup(group);
      client
        .post("/storage/root", { storage_sub_id: group.storage_sub_id })
        .then((res) => {
          const data = res?.data.data;
          const storage_sub_id = group.storage_sub_id;
          const folder_info = data.folder_info ?? [];
          const file_info = data.file_info ?? [];

          setStorageDirectory({ storage_sub_id, folder_info, file_info });
          navigate(`/mypage/storage/${storage_sub_id ?? ""}/${"root"}`);
          console.log(
            "[useStorage onGroupClick storage_sub_id]",
            group,
            storage_sub_id,
          );
          console.log(
            "[useStorage onGroupClick folder_info]",
            group,
            folder_info,
          );
          console.log("[useStorage onGroupClick file_info]", group, file_info);
        });

      // 판매목록 클릭으로 이동 예정임
      client
        .post("/sell/list", {
          from_dtm: fromDtm.format("YYYY-MM-DD"),
          to_dtm: toDtm.format("YYYY-MM-DD"),
        })
        .then((res) => {
          let data = res?.data?.data;

          if (data) {
            data = data.filter(
              (contract) => contract.seller_group_name === group.group_name,
            );
          }

          setSellContractList(data);
          console.log("[useStorage onGroupClick sell/list]", data);
        });
    };

  const onContractMenuClick = () => {
    client
      .post("/sell/list", {
        from_dtm: fromDtm.format("YYYY-MM-DD"),
        to_dtm: toDtm.format("YYYY-MM-DD"),
      })
      .then((res) => {
        let data = res?.data?.data;

        if (data) {
          data = data.filter(
            (contract) => contract.seller_group_name === group.group_name,
          );
        }

        setSellContractList(data);
        console.log("[useStorage onGroupClick sell/list]", data);
      });
  };

  const onFolderClick = (current) => () => {
    setFolder(current);
    getDirectory(current);
    setPath((pre) => {
      // 루트인 경우
      if (!current.folder_id || current.folder_id === "root") return "/root";

      if (folder?.parent_folder_id === current.folder_id) {
        const _ = pre.split("/");
        _.pop();

        return _.join("/");
      }
      return `${pre}/${current.folder_name}`;
    });
    navigate(
      `/mypage/storage/${storageDirectory.storage_sub_id}/${current.folder_id}`,
    );
  };

  const onFolderMoveClick = (current) => {
    setFolder(current);
    setFile(initFile);
    setExplorer((pre) => ({ ...pre, open: true }));
  };

  const onFileMoveClick = (current) => {
    setFile(current);
    setExplorer((pre) => ({ ...pre, open: true }));
  };

  const onFileClick =
    (file = initFile) =>
    () => {
      client.post("/file/meta/select", file).then((res) => {
        const data = res?.data?.data;

        console.log("[useStorage onFileClick]", file, data);
        setFile({
          ...data,
          data_sub_cat: data.data_sub_cat.join(",").replace(/[#]/g, ""),
          collect_from_dtm: dayjs(data.collect_from_dtm),
          collect_to_dtm: dayjs(data.collect_to_dtm),
          column_info: data.column_info.map((info, id) => ({ ...info, id })),
        });
      });
    };

  const onFileInfoClick =
    (file = initFile) =>
    () => {
      client.post("/file/meta/select", file).then((res) => {
        const data = res?.data?.data;

        console.log("[useStorage onFileClick]", file, data);
        setFile({
          ...data,
          data_sub_cat: data.data_sub_cat.join(",").replace(/[#]/g, ""),
          collect_from_dtm: dayjs(data.collect_from_dtm),
          collect_to_dtm: dayjs(data.collect_to_dtm),
          column_info: data.column_info.map((info, id) => ({ ...info, id })),
        });
        setIsShowPopup(true);
      });
    };

  const onFileDownloadClick =
    (file = initFile) =>
    async () => {
      try {
        // const data = (await client.post('/file/meta/select', {
        //   upload_file_id: file.upload_file_id
        // })).data

        setDownloadFile({ fileInfos: file });
        setQRcode((code) => ({
          ...code,
          data: {
            ...code.data,
            pulling_key: v4().toString(),
            encrypted_key: v4().toString(),
            storage_sub_id: group.storage_sub_id,
            user_id: sessionStorage.getItem("user_id"),
          },
        }));
        setIsShowQRDownloadPopup(true);
      } catch (err) {
        console.error("[useStorage] [onFileDownloadClick] error", err);
      }
    };

  const onContractClick =
    (contract = initContract) =>
    () => {
      client.post("/sell/select", contract).then((res) => {
        const data = res?.data.data;
        setSellContract(data);

        console.log("[useStorage onContractClick]", contract, data);
      });
    };

  const onCreateFolderClick = (folderInfo) => () => {
    // 스토리지 폴더 생성
    console.log("[useStorage onCreateFolder]", folderInfo);

    // parent_folder_id가 없으면 아얘 key를 없애기
    const result = {};

    result.storage_sub_id = folderInfo.storage_sub_id;
    result.subscription_id = folderInfo.subscription_id;

    if (folderInfo.parent_folder_id) {
      result.parent_folder_id = folderInfo.parent_folder_id;
    }

    folderInfo = result;
    // parent_folder_id가 없으면 아얘 key를 없애기

    const folderInsert = async (name) => {
      client
        .post("/folder/insert", { ...folderInfo, folder_name: name })
        .then((res) => {
          console.log("[useStorage onCreateFolder]", res);
          getDirectory();
          showSnackBar("폴더를 생성하였습니다");
        })
        .catch((err) => {
          console.error("[useStorage onCreateFolder]", err);
          showSnackBar(err.response.data.msg);
        });
    };

    setDialog({
      open: true,
      context: "폴더명을 입력하세요",
      value: "새 폴더",
      onChange: (event) => {
        setDialog((pre) => ({ ...pre, value: event.target.value }));
      },
      onClick: () => {
        setDialog((pre) => {
          const name = pre.value;
          folderInsert(name);
          console.log("[useStorage value]", name);

          return { open: false };
        });
      },
      onClose: () => setDialog({ open: false }),
    });

    // client
    //   .post("/folder/insert", folderInfo)
    //   .then((res) => {
    //     console.log("[useStorage onCreateFolder]", res);
    //   })
    //   .catch((err) => {
    //     console.error("[useStorage onCreateFolder]", err);
    //   });
  };

  const onMoveStorageFolderAndFile =
    (
      storage_sub_id,
      sourceFolderInfo,
      destinationFolderInfo,
      folderInfos,
      fileInfos,
    ) =>
    () => {
      // 스토리지 폴더 이동
      console.log(
        "[useStorage onMoveStorageFolder sourceFolderInfo]",
        sourceFolderInfo,
      );
      console.log(
        "[useStorage onMoveStorageFolder destinationFolderInfo]",
        destinationFolderInfo,
      );
      console.log("[useStorage onMoveStorageFolder folderInfos]", folderInfos);

      client
        .post("/file/move", {
          storage_sub_id,
          subscription_id: subscriptionId,
          source_folder_id: sourceFolderInfo,
          destination_folder_id: destinationFolderInfo,
          file_ids: fileInfos,
          folder_ids: folderInfos,
        })
        .then((res) => {
          console.log("[useStorage onMoveStorageFolder]", res);
          // 닫기
          setExplorer((pre) => ({ ...pre, open: false }));
          getDirectory();
          showSnackBar("이동하였습니다");
        })
        .catch((err) => {
          console.error("[useStorage onMoveStorageFolder]", err);
          // 닫기
          setExplorer((pre) => ({ ...pre, open: false }));
        });
    };

  const onDeleteStorageFolderClick = (folderInfo) => () => {
    // 스토리지 폴더 삭제
    console.log("[useStorage onDeleteStorageFolderClick]", folderInfo);

    client
      .post("/folder/delete", {
        folder_id: folderInfo.folder_id,
        storage_sub_id: folderInfo.storage_sub_id,
      })
      .then((res) => {
        console.log("[useStorage onCreateFolder]", res);
        getDirectory();
        showSnackBar("폴더를 삭제하였습니다");
      })
      .catch((err) => {
        if (err.response.status === 409) {
          showSnackBar(
            `${err.response.data.data}건의 계약에 업로드되어 삭제할 수 없습니다`,
          );
        } else {
          showSnackBar(err.message);
        }
        console.error("[useStorage onCreateFolder]", err);
      });
  };

  const onUploadStorageFilePopupClick = () => {
    setIsShowPopup(true);
  };

  const onUploadStorageFilePopupClose = () => {
    setIsShowPopup(false);
  };

  const onUploadStorageUploadPopupClick = () => {};
  const onUploadStorageUploadPopupClose = (qrcode, result) => {
    console.log(
      "[useStorage]",
      "[onUploadStorageUploadPopupClose]",
      qrcode,
      result,
    );
    setIsShowQRUploadPopup(false);

    // 이쪽에서 파일 업로드
    if (result?.key) {
      const encrypted_key = AES.decrypt(
        result.key,
        qrcode?.data?.encrypted_key,
      ).toString(CryptoJS.enc.Utf8);
      const { file, fileInfos } = uploadFile;

      console.log("[useStorage] [encrypt start time]", new Date());
      console.log(
        "[useStorage]",
        "[onUploadStorageUploadPopupClose decrypt]",
        encrypted_key,
      );
      try {
        console.log(
          "[ModalFileInfo onUploadStorageFileChange file]",
          file.current.files[0],
        );

        let files = {};
        Array.from(file.current.files).forEach((file) => {
          const item = {
            [file.name]: {
              upload_main: {
                data_title: fileInfos.data_title, //
                data_main_cat: fileInfos.data_main_cat, // 메인 카테고리
                data_sub_cat: fileInfos.data_sub_cat
                  .split(",")
                  .map((info) => `#${info}`), // 서l브 카테고리
                data_price: fileInfos.data_price, // 가격
                data_desc: fileInfos.data_desc, // 설명
                data_ext: fileInfos.data_ext, // 확장자
                data_size: fileInfos.data_size, // 데이터 크기
                collect_to_dtm: fileInfos.collect_to_dtm, // 수집기간
                collect_from_dtm: fileInfos.collect_from_dtm, // 수집기간
                collect_machine: fileInfos.collect_machine, // 수집장비
                collect_product: fileInfos.collect_product, // 수집공정
                collect_process: fileInfos.collect_process, // 수집제품
                upload_subs: fileInfos.column_info.map((row) => {
                  delete row.id;
                  return row;
                }),
              },
            },
          };

          files = { ...files, ...item };
        });

        const schema = {
          storage_sub_id: group.storage_sub_id,
          subscription_id:
            OneSignal.User.PushSubscription.id ?? v4().toString(),
          folder_id:
            folder?.folder_id === "root" ? undefined : folder?.folder_id,
          files: files,
        };
        console.log("[useStorage onUploadStorageFileChange schema]", schema);

        const _files = new FormData();
        _files.append("info", JSON.stringify(schema));
        console.log(
          "[useStorage onUploadStorageFileChange files]",
          file.current.files,
        );
        Array.from(file.current.files).forEach((file) => {
          // 암호화 적용하기
          // 2. 선택한 파일을 base64로 변환
          console.log("[useStorage] [encrypt base64 start time]", new Date());
          console.log("[useStorage] [encrypt file]", file);
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          console.log("[useStorage] [encrypt base64 end time]", new Date());
          fileReader.onload = ({ target }) => {
            console.log(
              "[useStorage] [encrypt base64 onload start time]",
              new Date().toISOString(),
            );
            const base64 = target.result;
            console.log("## FILE", target.result);
            // 3. 암호화
            try {
              const encode = AES.encrypt(base64, encrypted_key).toString();
              console.log("## FILE ENCODE", encode);

              const _blob = new Blob([encode]);
              _files.append("files", _blob, file.name);
            } catch (error) {
              console.log("## FILE ENCODE ERROR", error);
            }
            console.log(
              "[useStorage] [encrypt base64 onload end time]",
              new Date().toISOString(),
            );
          };

          // _files.append("files", file);
        });

        (async (files, length) => {
          const _ = () => {
            if (length === Array.from(files.getAll("files")).length) {
              clearInterval(interval);
              console.log(
                "[useStorage] [encrypt upload start time]",
                new Date(),
              );
              client
                .post("/file/upload", files, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                .then((res) => {
                  console.log(
                    "[useStorage] [encrypt upload start time]",
                    new Date(),
                  );
                  console.log(
                    "[useStorage onUploadStorageFileChange /file/upload]",
                    res,
                  );
                  // alert("업로드 완료");
                  setIsShowPopup(false);
                  getDirectory();
                  showSnackBar("파일 업로드를 완료하였습니다.");
                })
                .catch((err) => {
                  console.log(
                    "[useStorage] [encrypt upload start time]",
                    new Date(),
                  );
                  console.error(
                    "[useStorage onUploadStorageFileChange error /file/upload]",
                    err,
                  );
                  showSnackBar(err.response.data.msg);
                });
            }
          };
          const interval = setInterval(_, 100);
        })(_files, Array.from(file.current.files).length);
      } catch (err) {
        console.error("[useStorage onUploadStorageFileChange]", err);
      }
    }
  };
  const onUploadStorageDownloadPopupClick = () => {};
  const onUploadStorageDownloadPopupClose = (qrcode, result) => {
    console.log(
      "[useStorage]",
      "[onUploadStorageDownloadPopupClose]",
      qrcode,
      result,
    );
    setIsShowQRDownloadPopup(false);

    // 이쪽에서 파일 다운로드
    if (result?.key) {
      const decrypted_key = AES.decrypt(
        result.key,
        qrcode?.data?.encrypted_key,
      ).toString(CryptoJS.enc.Utf8);
      const { fileInfos } = downloadFile;

      console.log("[useStorage fileInfos]", fileInfos);
      console.log("[decrypted_key]", decrypted_key);

      try {
        // 파일 다운로드
        client
          .post("/file/download", {
            file_id: fileInfos.upload_file_id,
            folder_id:
              folder?.folder_id === "root" ? undefined : folder?.folder_id,
            storage_sub_id: group.storage_sub_id,
          })
          .then((res) => {
            const { file_name, file_ext, payload } = res.data?.data;

            console.log(
              "[useStorage]",
              "[onFileDownloadClick]",
              "[file_name]",
              file_name,
            );
            console.log(
              "[useStorage]",
              "[onFileDownloadClick]",
              "[file_ext]",
              file_ext,
            );
            console.log(
              "[useStorage]",
              "[onFileDownloadClick]",
              "[paylaod]",
              payload,
            );

            // 4. 복호화
            const decodeBase64 = atob(payload);
            const decode = AES.decrypt(decodeBase64, decrypted_key).toString(
              CryptoJS.enc.Utf8,
            );
            console.log("## FILE DECODE", decode);
            // 5. base64를 원래 파일로 변환
            const src = decode;

            // 다운로드
            const link = document.createElement("a");
            link.href = src;
            link.download = file_name;
            link.click();
          });
      } catch (err) {
        console.error("[useStorage onUploadStorageFileChange]", err);
      }
    }
  };

  const onUploadStorageFile = (file, fileInfos) => async () => {
    setUploadFile({ file, fileInfos });
    setQRcode((code) => ({
      ...code,
      data: {
        ...code.data,
        pulling_key: v4().toString(),
        encrypted_key: v4().toString(),
        storage_sub_id: group.storage_sub_id,
        user_id: sessionStorage.getItem("user_id"),
      },
    }));
    setIsShowQRUploadPopup(true);

    // 스토리지 파일 업로드
    console.log("[useStorage onUploadStorageFile]");
  };

  const onUploadStorageFileInfo = (file, fileInfos) => async () => {
    setUploadFile({ file, fileInfos });

    const data = {
      upload_main_id: fileInfos.upload_main_id,
      main_info: {
        data_title: fileInfos.data_title,
        data_main_cat: fileInfos.data_main_cat,
        data_sub_cat: fileInfos.data_sub_cat
          .split(",")
          .map((info) => `#${info}`),
        data_price: fileInfos.data_price,
        data_desc: fileInfos.data_desc,
        data_ext: fileInfos.data_ext,
        data_size: fileInfos.data_size,
        collect_from_dtm: dayjs(fileInfos.collect_from_dtm).format(
          "YYYY-MM-DD",
        ),
        collect_to_dtm: dayjs(fileInfos.collect_to_dtm).format("YYYY-MM-DD"),
        collect_process: fileInfos.collect_process,
        collect_machine: fileInfos.collect_machine,
        collect_product: fileInfos.collect_product,
      },
      column_info: fileInfos.column_info,
    };

    client
      .post("/file/meta/update", data)
      .then((res) => {
        showSnackBar("수정되었습니다");
      })
      .catch((err) => {
        // setIsShowPopup(false);
        console.log(
          "[useStorage onUploadStorageFileInfo fileInfos error]",
          err.response,
        );
        showSnackBar(err.response.data.msg);
        // alert(JSON.stringify(err.response));
      });

    // 스토리지 파일 업로드
    console.log("[useStorage onUploadStorageFileInfo file]", file);
    console.log("[useStorage onUploadStorageFileInfo fileInfos]", fileInfos);
  };

  const onUploadStorageFileChange = (event) => {
    try {
      console.log("[useStorage onUploadStorageFileChange]", event);
      console.log(
        "[useStorage onUploadStorageFileChange file]",
        event.target.files[0],
      );

      let files = {};
      Array.from(event.target.files).forEach((file) => {
        const fileSize = file.size;
        const fileExt = file.name.split(".").pop();
        const item = {
          [file.name]: {
            upload_main: {
              ...initUploadFile.upload_main,
              data_ext: fileExt, // 확장자
              data_size: fileSize, // 데이터 크기
              upload_subs: [], // 컬럼 정보
            },
          },
        };

        files = { ...files, ...item };
      });

      const schema = {
        storage_sub_id: storageDirectory.storage_sub_id,
        subscription_id: subscriptionId,
        folder_id: folder?.folder_id,
        files: files,
      };
      console.log("[useStorage onUploadStorageFileChange schema]", schema);

      const _files = new FormData();
      _files.append("info", JSON.stringify(schema));
      Array.from(event.target.files).forEach((file) => {
        _files.append("files", file);
      });
      client
        .post("/file/upload", _files, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) =>
          console.log(
            "[useStorage onUploadStorageFileChange /file/upload]",
            res,
          ),
        )
        .catch((err) =>
          console.error(
            "[useStorage onUploadStorageFileChange /file/upload]",
            err,
          ),
        );
    } catch (err) {
      console.error("[useStorage onUploadStorageFileChange]", err);
    }
  };
  const onMoveStorageFile =
    (storage_sub_id, sourceFolderInfo, destinationFolderInfo, fileInfos) =>
    () => {
      // 스토리지 파일 이동
      console.log(
        "[useStorage onMoveStorageFile sourceFolderInfo]",
        sourceFolderInfo,
      );
      console.log(
        "[useStorage onMoveStorageFile destinationFolderInfo]",
        destinationFolderInfo,
      );
      console.log("[useStorage onMoveStorageFile folderInfos]", fileInfos);

      client
        .post("/file/move", {
          storage_sub_id,
          subscription_id: subscriptionId,
          source_folder_id: sourceFolderInfo,
          destination_folder_id: destinationFolderInfo,
          file_ids: fileInfos,
        })
        .then((res) => {
          console.log("[useStorage onMoveStorageFile]", res);
        })
        .catch((err) => {
          console.error("[useStorage onMoveStorageFile]", err);
        });
    };
  const onDeleteStorageFileClick = (fileInfo) => () => {
    // 스토리지 파일 삭제
    console.log("[useStorage onDeleteStorageFileClick]", fileInfo);

    const removeFile = async () => {
      client
        .delete("/file/remove", {
          data: {
            storage_sub_id: storageDirectory.storage_sub_id,
            upload_file_id: fileInfo.upload_file_id,
          },
        })
        .then((res) => {
          console.log("[useStorage onDeleteStorageFileClick]", res);
          getDirectory();
          showSnackBar("파일을 삭제하였습니다");
        })
        .catch((err) => {
          if (err.response.status === 409) {
            showSnackBar(
              `${err.response.data.data}건의 계약에 업로드되어 삭제할 수 없습니다`,
            );
          } else {
            showSnackBar(err.message);
          }
          console.error("[useStorage onDeleteStorageFileClick]", err);
        });
    };

    setDialog({
      open: true,
      context: "파일을 삭제하시겠습니까?",
      onClick: () => {
        setDialog({ open: false });
        removeFile();
      },
      onClose: () => setDialog({ open: false }),
    });
  };

  const onMappingFilesForContractClick =
    (contract, folderInfos, fileInfos) => () => {
      if (!file || !file.upload_file_id) {
        showSnackBar("추가할 파일을 선택하세요");
        return;
      }

      // 판매계약 파일 매핑
      console.log("[useStorage onMappingFilesForContractClick file]", file);
      console.log("[useStorage onMappingFilesForContractClick folder]", folder);
      console.log(
        "[useStorage onMappingFilesForContractClick contract]",
        contract,
      );
      console.log(
        "[useStorage onMappingFilesForContractClick storage]",
        storageDirectory.storage_sub_id,
      );
      console.log(
        "[useStorage onMappingFilesForContractClick fileInfos]",
        fileInfos,
      );

      client
        .post("/sell/select", {
          contract_sub_id: contract,
        })
        .then((res) => {
          const dataType = res.data.data.data_type;
          const ext = file.data_ext;
          let check = false;
          let extName = "";

          switch (dataType) {
            case "800":
              check = ext === "json";
              extName = "json";
              break;
            case "801":
              check = ext === "csv";
              extName = "csv";
              break;
            case "802":
              check = (ext === "xlsx") | (ext === "xls");
              extName = "Excel";
              break;
            case "803":
              check = ext === "txt";
              extName = "TXT";
              break;
            case "804":
              check = ext === "xml";
              extName = "XML";
              break;
            case "805":
              check = ext === "zip";
              extName = "Zip";
              break;
            case "806":
              check = ext === "pdf";
              extName = "PDF";
              break;
            default:
          }

          if (check) {
            client
              .post("/file/addmapping", {
                contract_sub_id: contract,
                storage_sub_id: storageDirectory.storage_sub_id,
                folder_id: folder?.folder_id,
                file_ids: [file.upload_file_id],
              })
              .then((res) => {
                // console.log("[useStorage onMappingFilesForContractClick]", res),
                showSnackBar("파일을 추가하였습니다");
                // 파일 리스트 갱신
                onContractClick(sellContract)();
              })
              .catch((err) => {
                // console.error("[useStorage onMappingFilesForContractClick]", err),
                showSnackBar(err.response.data.msg);
              });
          } else {
            showSnackBar(
              `계약서에 지정한 ${extName} 확장자와 동일한 파일을 추가하세요`,
            );
          }
        });
    };
  const onUnMappingFilesForContractClick = (contract, fileInfos) => () => {
    // 판매계약 파일 언매핑
    console.log(
      "[useStorage onUnMappingFilesForContractClick contract]",
      contract,
    );
    console.log(
      "[useStorage onUnMappingFilesForContractClick fileInfos]",
      fileInfos,
    );

    const removeMapping = async () => {
      client
        .post("/file/removemapping", {
          contract_sub_id: contract,
          down_file_ids: fileInfos,
        })
        .then((res) => {
          // console.log("[useStorage onMappingFilesForContractClick]", res),
          showSnackBar("파일을 제외하였습니다");
          // 파일 리스트 갱신
          onContractClick(sellContract)();
        })
        .catch((err) => {
          // console.error("[useStorage onMappingFilesForContractClick]", err),
          showSnackBar(err.response.data.msg);
        });
    };

    setDialog({
      open: true,
      context: "파일을 제외하시겠습니까?",
      onClick: () => {
        removeMapping();
        setDialog({ open: false });
      },
      onClose: () => {
        setDialog({ open: false });
      },
    });
  };

  useEffect(() => {
    onLoadGroup();
  }, []);

  useEffect(() => {
    onContractMenuClick();
  }, [fromDtm, toDtm]);

  return {
    group: {
      select: group,
      list: groupList,
      setGroup,
    },
    storage: {
      directories: storageDirectory,
      select: { folder, path },
    },
    qrcode: {
      isShowUploadPopup: isShowQRUploadPopup,
      isShowDownloadPopup: isShowQRDownloadPopup,
      code: qrcode,
    },
    file: {
      select: file,
      isShowPopup: isShowFilePopup,
    },
    contract: {
      select: sellContract,
      list: sellContractList,
    },
    dialog: dialog,
    explorer: explorer,
    snackBar: snackBar,
    fromDtm,
    toDtm,
    onFromChange,
    onToChange,
    onUnbindClick,
    onGroupClick,
    onContractClick,
    onFolderClick,
    onFolderMoveClick,
    onFileClick,
    onFileInfoClick,
    onFileMoveClick,
    onFileDownloadClick,
    onCreateFolderClick,
    onMoveStorageFolderAndFile,
    onDeleteStorageFolderClick,
    onUploadStorageFilePopupClick,
    onUploadStorageFilePopupClose,
    onUploadStorageUploadPopupClick,
    onUploadStorageUploadPopupClose,
    onUploadStorageDownloadPopupClick,
    onUploadStorageDownloadPopupClose,
    onUploadStorageFileInfo,
    onUploadStorageFile,
    onUploadStorageFileChange,
    onMoveStorageFile,
    onDeleteStorageFileClick,
    onMappingFilesForContractClick,
    onUnMappingFilesForContractClick,
  };
}
