import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  List,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { OndemandVideoIcon } from "components/icons/IconMui";
const drawerWidth = 240;

export const menuItems = [
  {
    text: "회원가입",
    route: "/guide/signup",
    Icon: OndemandVideoIcon,
  },
  {
    text: "로그인",
    route: "/guide/login",
    Icon: OndemandVideoIcon,
  },
  {
    text: "일반계약",
    route: "/guide/contract",
    Icon: OndemandVideoIcon,
  },
  {
    text: "플랫폼계약",
    route: "/guide/platform",
    Icon: OndemandVideoIcon,
  },
];

const GuideNav = ({
  window,
  mobileOpen,
  handleDrawerClose,
  setSelectedMenuItem,
  listItemStyle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleMenuItemClick = (item) => {
    navigate(item.route); // 메뉴 항목에 따라 URL 변경
    handleDrawerClose(); // 모바일에서 메뉴 선택 후 드로어 닫기
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const MenuList = () => {
    return (
      <Box>
        <List>
          {menuItems.map((item, index) => (
            // 베이직이 아니어야만 사용자관리 메뉴 표시
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                sx={listItemStyle(item.text)}
                onClick={() => handleMenuItemClick(item)}
              >
                <ListItemIcon>
                  <item.Icon />
                  {item.AltIcon && <item.AltIcon />}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  useEffect(() => {
    const activeItem = menuItems.find((item) =>
      location.pathname.includes(item.route),
    );
    if (activeItem) {
      setSelectedMenuItem(activeItem);
    }
  }, [location]);

  return (
    <Box
      component="nav"
      sx={{
        display: "flex",
        //   width: drawerWidth,
        flexShrink: { sm: 0 },
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerClose}
        // open={open}
        // onClose={onClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,

          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            height: "100%",
          },
        }}
      >
        <MenuList />
      </Drawer>
      {/* 모바일 */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            position: "relative",
            // borderColor: "pink",
            // borderLeft: "1px solid #000",
            borderRight: "unset",
          },
        }}
        open
      >
        <MenuList />
      </Drawer>
      {/* PC */}
    </Box>
  );
};
GuideNav.propTypes = {
  window: PropTypes.any,
};
export default GuideNav;
